@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Space Grotesk', system-ui, sans-serif;
  }
  body {
    @apply text-gray-200 bg-gray-900;
  }
  h1 {
    @apply text-4xl font-bold mb-4;
  }
  h2 {
    @apply text-3xl font-semibold mb-3;
  }
  h3 {
    @apply text-2xl font-semibold mb-2;
  }
  h4 {
    @apply text-xl font-semibold mb-2;
  }
  p {
    @apply mb-4;
  }
}

@layer components {
  .btn {
    @apply px-4 py-2 rounded-md font-medium transition duration-300;
  }
  .btn-primary {
    @apply bg-blue-600 text-white hover:bg-blue-700;
  }
  .input {
    @apply p-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500;
  }
}

/* Mobile optimizations */
@media screen and (max-width: 768px) {
  html, body {
    touch-action: pan-x pan-y;
    -webkit-text-size-adjust: 100%;
    overscroll-behavior-y: none; /* Prevents pull-to-refresh on mobile */
  }

  /* Improve tap target sizes for mobile */
  button, 
  a,
  .btn {
    min-height: 44px; /* Apple's recommended minimum touch target size */
    min-width: 44px;
    padding: 0.5rem 1rem;
  }

  /* Adjust text sizes for better mobile readability */
  h1 {
    @apply text-3xl; /* Slightly smaller than desktop */
  }
  
  h2 {
    @apply text-2xl;
  }

  /* Improve table handling on mobile */
  table {
    display: block;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

/* Smooth scrolling for the entire site */
html {
  scroll-behavior: smooth;
  /* Prevent content shift when scrollbar appears/disappears */
  scrollbar-gutter: stable;
}

/* Better focus states for accessibility */
:focus-visible {
  outline: 2px solid theme('colors.blue.400');
  outline-offset: 2px;
}

/* Better touch feedback */
@media (hover: none) {
  .btn:active,
  button:active,
  a:active {
    transform: scale(0.98);
  }
}

/* Reduce motion if user prefers */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

@keyframes rotate {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@keyframes borderShine {
  0% {
    background-position: 
      0% 100%,
      100% 0%,
      0% 100%,
      0% 0%;
  }
  25% {
    background-position: 
      100% 100%,
      100% 0%,
      0% 100%,
      0% 0%;
  }
  50% {
    background-position: 
      100% 0%,
      100% 100%,
      0% 0%,
      0% 100%;
  }
  75% {
    background-position: 
      0% 0%,
      100% 100%,
      100% 0%,
      0% 100%;
  }
  100% {
    background-position: 
      0% 100%,
      100% 0%,
      100% 100%,
      0% 0%;
  }
}
.animate-border-shine {
  animation: borderShine 60s linear infinite;
}

.social-auth-button {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.twitter-login-button {
  width: 280px !important;
  height: 40px !important;  /* Changed from 50px to match Google's height */
  border-radius: 20px !important;  /* Changed to match Google's pill shape */
  /* Adjust padding if needed */
  padding: 1 !important;
}

/* Optional: adjust the icon size if it looks too large after height reduction */
.twitter-login-button svg {
  width: 18px !important;
  height: 18px !important;
}

/* Optional: adjust text size if needed */
.twitter-login-button > div {
  font-size: 14px !important;
}